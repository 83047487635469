<template>
  <BaseLayout>
    <SubHeader></SubHeader>
    <div class="container" style="margin-bottom: 40px;">
      <a-steps progress-dot :current="step" :initial="1">
        <a-step title="Step 1" description="Enter Advertisement Details" />
        <a-step title="Step 2" description="Confirmation" />
        <a-step title="Step 3" description="Complete" />
      </a-steps>
    </div>
    <div style="text-align: center; margin: 20px 0;" v-if="loading"><a-spin /></div>
    <div v-if="step === 1 && loading === false">
      <div class="container">
        <div class="form-group">
          <label>Business Name</label>
          <a-input v-model:value="form.business_name" />
        </div>
        <div class="form-group">
          <label>Website</label>
          <a-input v-model:value="form.website" />
        </div>
        <div class="form-group">
          <label>Search Address</label>
          <input type="text" id="autocomplete_search" class="form-control" :disabled="mapAutoComplete === null" :placeholder="mapAutoComplete === null ? 'Loading...' : 'Enter a location'" :value="this.form.address.address" />
        </div>
        <div style="margin-top: 20px;">
          <label>Upload Ad Image</label>
          <div>
            <input type="file" ref="images" accept="image/png, image/jpeg" />
          </div>
          <div class="d-flex justify-content-start flex-wrap" v-if="form.images.length > 0">
            <div v-for="image, index in form.images" :key="index" style="margin-right: 10px; margin-bottom: 10px; position: relative;">
              <img :src="image" style="object-fit: cover; height: 150px; border-top-right-radius: 4px;" />
              <div style="position: absolute; right: 0; top: 0; background: #fff; padding: 4px 8px; background: #eee; border-top-right-radius: 4px; border-bottom-left-radius: 4px;">
                <a href="javascript:;" @click="removeImage(index);"><svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                </svg></a>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 20px;">
          <h4 style="text-align: center; margin-bottom: 20px;">Your contact information</h4>
          <div class="form-group">
            <div>
              <a-radio-group v-model:value="form.contact_information">
                <div><a-radio value="default">Use my account contact information</a-radio></div>
                <div><a-radio value="other">Use other contact information</a-radio></div>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Name</label>
          <a-input v-model:value="form.contact.name" />
        </div>
        <div class="form-group">
          <label>Phone</label>
          <a-input v-model:value="form.contact.phone" />
        </div>
        <div class="form-group">
          <label>Email</label>
          <a-input v-model:value="form.contact.email" />
        </div>
      </div>
      <hr />
      <div class="container">
        <div class="d-flex justify-content-between">
          <router-link to="/my-ads" class="btn">Cancel</router-link>
          <button class="btn btn-primary" @click="previewListing()" :disabled="imagesAdded !== imagesUploaded">Preview Listing</button>
        </div>
      </div>
    </div>
    <div v-if="step === 2 && loading === false">
      <div class="container">
        <div style="text-align: center">
          <template v-if="Array.isArray(form.images)">
            <div v-for="image in form.images" :key="image">
              <img :src="image" style="width: 300px; height: 250px; object-fit: cover" />
            </div>
          </template>
        </div>
        <hr />
        <div class="d-flex justify-content-between">
          <button class="btn btn-info" @click="step = 1">Edit Advertisement</button>
          <router-link class="btn btn-primary" :to="{path: '/ads-plans', query: {next_page: 'save-ads', next_id: form.id}}" v-if="form.subscription_id == null">Choose Your Ad Plan</router-link>
          <button class="btn btn-primary" @click="publishListing()" v-if="form.subscription_id != null">Publish Listing</button>
        </div>
      </div>
    </div>
    <div v-if="step === 3 && loading === false">
      <div class="container">
        <h4 style="text-align: center; margin: 20px 0;">Success!</h4>
        <p style="text-align: center">Thank you!</p>
      </div>
      <hr />
      <div class="container">
        <div class="d-flex justify-content-between">
          <button class="btn btn-info" @click="step = 1">Edit Advertisement</button>
          <router-link class="btn btn-primary" to="/my-ads">Close</router-link>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import {Loader} from '@googlemaps/js-api-loader';
import axios from 'axios';
import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';
import Detail from '@/components/Detail.vue';
import * as FilePond from 'filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import heic2any from 'heic2any';
import { fromBlob } from 'image-resize-compress';
import { heicTo } from "heic-to"

// AntVue
import ASteps from 'ant-design-vue/lib/steps';
import AInput from 'ant-design-vue/lib/input';
import ARadio from 'ant-design-vue/lib/radio';
import ASpin from 'ant-design-vue/lib/spin';
import 'ant-design-vue/lib/steps/style/index.css';
import 'ant-design-vue/lib/input/style/index.css';
import 'ant-design-vue/lib/input-number/style/index.css';
import 'ant-design-vue/lib/radio/style/index.css';
import 'ant-design-vue/lib/select/style/index.css';
import 'ant-design-vue/lib/spin/style/index.css';
import 'ant-design-vue/lib/upload/style/index.css';
import 'ant-design-vue/lib/button/style/index.css';
import Swal from "sweetalert2";
import Constants from '@/constants/constants';

export default {
  components: {
    BaseLayout,
    SubHeader,
    AInput,
    ASpin,
    Detail,
    'a-steps': ASteps,
    'a-step': ASteps.Step,
    'a-radio': ARadio,
    'a-radio-group': ARadio.Group,
  },
  data() {
    return {
      loading: false,
      imagesAdded: 0,
      imagesUploaded: 0,
      step: 1,
      formatter: null,
      google_maps_loader: null,
      mapAutoCompleteListener: null,
      mapAutoComplete: null,
      pond: null,
      new_images: [],
      form: {
        id: null,
        subscription_id: null,
        business_name: null,
        website: null,
        address: {
          address: null,
          state: null,
          city: null,
          country: null,
          postal_code: null,
          lat: null,
          lng: null,
        },
        contact_information: 'default',
        contact: {
          name: this.$store.state.user.displayName,
          phone: this.$store.state.user.phoneNumber,
          email: this.$store.state.user.email
        },
        images: []
      }
    }
  },
  async mounted() {
    this.formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    });
    document.title = 'Create Advertisement - Mane Street Market';
    window.scrollTo(0, 0);
    if (this.$route.query.id) {
      await this.loadListing(this.$route.query.id)
    }
    this.google_maps_loader = new Loader({
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      libraries: ['places'],
      version: Constants.GOOGLE_MAPS_VERSION
    });
    await this.google_maps_loader.load();
    if (this.$route.query.payment_completed && this.form.subscription_id != null) {
      this.step = 2;
      this.publishListing();
    }
    this.setUpMapAndUploader();
  },
  unmounted() {
    if (this.mapAutoCompleteListener) {
      window.google.maps.event.removeListener(this.mapAutoCompleteListener);
    }
    if (this.mapAutoComplete) {
      window.google.maps.event.clearInstanceListeners(this.mapAutoComplete);
    }
    this.google_maps_loader.deleteScript();
  },
  watch: {
    step(newValue) {
      window.scrollTo(0, 0);
      if (newValue === 1) {
        this.setUpMapAndUploader();
      }
    },
    'form.contact_information'() {
      if (this.form.contact_information === 'default') {
        this.form.contact.name = this.$store.state.user.displayName;
        this.form.contact.email = this.$store.state.user.email;
        this.form.contact.phone = this.$store.state.user.phoneNumber;
      } else {
        this.form.contact.name = null;
        this.form.contact.email = null;
        this.form.contact.phone = null;
      }
    }
  },
  methods: {
    setUpMapAndUploader() {
      setTimeout(() => {
        FilePond.registerPlugin(
          FilePondPluginFileValidateType,
          FilePondPluginImagePreview,
          FilePondPluginFileEncode,
          FilePondPluginImageResize,
          FilePondPluginImageTransform,
          FilePondPluginFileValidateSize
        );
        this.pond = FilePond.create(this.$refs.images, {
          allowFileEncode: true,
          allowMultiple: true,
          acceptedFileTypes: ['image/png', 'image/jpeg', 'image/heic', 'image/heic-sequence'],
          fileValidateTypeDetectType: (source, type) => new Promise((resolve, reject) => {
            let name = source.name.toLowerCase();
            if (name.includes('.heic')) {
              resolve('image/heic');
            } else if (name.includes('.heics')) {
              resolve('image/heic-sequence');
            } else {
              resolve(type);
            }
          }),
          imageResizeTargetWidth: 1000,
          imageResizeTargetHeight: 1000,
          imageResizeUpscale: false,
          imageTransformOutputQuality: 60,
          imageTransformOutputMimeType: 'image/jpeg',
          maxFileSize: '10MB',
          labelMaxFileSizeExceeded: 'Photo too large. Please compress to a max of 10MB.'
        });

        this.pond.oninitfile = (f) => {
          this.imagesAdded++;
        };
        this.pond.onaddfile = (err, f) => {
          this.imagesUploaded++;
        };

        this.mapAutoComplete = new window.google.maps.places.Autocomplete(document.getElementById('autocomplete_search'));
        // this.mapAutoComplete.setComponentRestrictions({
        //   country: ["us", "ca", "ie", "uk"],
        // });
        this.mapAutoCompleteListener = window.google.maps.event.addListener(this.mapAutoComplete, 'place_changed', this.searchOnMap);
      }, 2000);
    },
    async loadListing(id) {
      this.loading = true;
      try {
        let response = await axios.get('/api/ads/' + id);
        this.form = {
          id: id,
          business_name: response.data.business_name,
          website: response.data.website || null,
          subscription_id: response.data.subscription_id || null,
          contact_information: response.data.contact_information,
          contact: response.data.contact || {
            name: null,
            phone: null,
            email: null
          },
          images: response.data.images || [],
          address: response.data.address || {
            address: null,
            lat: null,
            lng: null,
            postal_code: null,
            city: null,
            state: null,
            country: null
          }
        };
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    searchOnMap() {
      let place = this.mapAutoComplete.getPlace();
      this.form.address.address = place.formatted_address;
      this.form.address.postal_code = null;
      this.form.address.city = null;
      this.form.address.state = null;
      this.form.address.country = null;
      this.form.address.lat = place.geometry['location'].lat();
      this.form.address.lng = place.geometry['location'].lng();
      for (const component of place.address_components) {
        const addressTypes = component.types;
        if (addressTypes.includes('postal_code')) {
          this.form.address.postal_code = component.short_name;
        } else if (addressTypes.includes('country')) {
          this.form.address.country = component.long_name;
        } else if (addressTypes.includes('administrative_area_level_1')) {
          this.form.address.state = component.short_name;
        } else if (addressTypes.includes('locality')) {
          this.form.address.city = component.long_name;
        } else if (addressTypes.includes('administrative_area_level_3') && this.form.address.city == null) { // do not overwrite locality
          this.form.address.city = component.long_name;
        }
      }
    },
    async previewListing() {
      if (this.form.website) {
        let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        if (!pattern.test(this.form.website)) {
          Swal.fire({
            title: 'There was an error with your listing',
            text: 'Please enter a valid website or leave it blank'
          });
          return; // not a valid URL
        }
      }

      this.loading = true;
      try {
        this.new_images = [];
        for (let image of this.pond.getFiles()) {
          if (image.fileExtension.toLowerCase() === 'heic' || image.fileExtension.toLowerCase() === 'heics') {
            const blob = this.b64toBlob(image.getFileEncodeBase64String())
            const jpgBlobs = [await heicTo({
              blob,
              type: 'image/jpeg',
              quality: 1
            })];
            for (let jpgBlob of jpgBlobs) {
              const smallerJpg = await fromBlob(jpgBlob, 60, 1000, 'auto', 'jpeg');
              const b64 = await this.blobToBase64(smallerJpg);
              this.new_images.push({
                file: image.file,
                size: image.fileSize,
                extension: 'jpeg',
                data: b64.replace('data:image/jpeg;base64,', '')
              });
            }
          } else {
            this.new_images.push({
              file: image.file,
              size: image.fileSize,
              extension: image.fileExtension,
              data: image.getFileEncodeBase64String()
            });
          }
        }
        let response = await axios.post('/api/ads/details', {
          id: this.form.id,
          business_name: this.form.business_name,
          website: this.form.website,
          contact_information: this.form.contact_information,
          contact: this.form.contact,
          images: this.form.images,
          new_images: this.new_images,
          address: this.form.address
        });
        this.form.id = response.data.id;
        this.form.images = response.data.images;
        this.step = 2;
      } catch (error) {
        Swal.fire({
          title: 'There was an error with your listing',
          text: error.response?.data?.error?.message ?? 'Please fill required fields!'
        });
        this.setUpMapAndUploader();
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async publishListing() {
      this.loading = true;
      try {
        await axios.post('/api/ads/publish', {id: this.form.id});
        this.step = 3;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    removeImage(index) {
      this.form.images.splice(index, 1);
    },
    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, {type: contentType});
      return blob;
    },
    blobToBase64(blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep(.ant-steps) {
    padding-top: 4px;
    overflow: hidden;
    justify-content: center;

    .ant-steps-item {
      max-width: 33%;
    }

    .ant-steps-item-process .ant-steps-item-icon {
      width: 8px;
      height: 8px;
    }
  }
</style>
